<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="userModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                    label="Correo Electrónico"
                    type="email"
                    :lazy="false"
                    :value.sync="$v.form.email.$model"
                    :isValid="checkIfValid('email')"
                    placeholder=""
                    autocomplete="email"
                    invalidFeedback="Este campo es requerido y debe ser un correo electrónico válido."
                />
              </CCol>
            </CRow>
            <CRow>
                <CCol md="6">
                <CInput
                    :isValid="checkIfValid('password')"
                    :value.sync="$v.form.password.$model"
                    label="Contraseña"
                    type="password"
                    placeholder=""
                    autocomplete="new-password"
                    invalidFeedback="Debe tener al menos 8 caracteres y teniendo al menos un número, una mayúscula y una minúscula."
                />
                </CCol>
                <CCol md="6">
                <CInput
                    :isValid="checkIfValid('confirmPassword')"
                    :value.sync="$v.form.confirmPassword.$model"
                    label="Confirmar Contraseña"
                    type="password"
                    placeholder=""
                    autocomplete="new-password"
                    invalidFeedback="Las contraseñas no coinciden."
                />
                </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="sendWelcomeMessage" color="info">Reenviar Correo de Bienvenida</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../../services/users';

export default {
  name: 'UserModal',
  props: {

  },
  data () {
    return {
      userModal: false,
      title: "Editar Usuario",
      color: "success",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
    }
  },
  mounted: async function() {

  },
  methods: {
    updateModal (item) {
      this.userModal = true;
      this.color = "success";
      this.title = 'Editar Usuario';
      this.isEdit = true;
      this.submitted = false;
      
      this.form = {
          id: item.id,
          name: item.customer_name,
          email: item.customer_email,
          password: 'Password07',
          confirmPassword: 'Password07'
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("update", this.form );

        this.closeModal();
      }
    },
    sendWelcomeMessage () {
      this.$emit("send", this.form );

      this.closeModal();
    },
    closeModal () {
      this.userModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          email: '',
          password: '',
          confirmPassword: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
}
</script>